import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { APIConstant } from '../constants/APIConstants';
import { lastValueFrom } from 'rxjs';
import { IVerifyNin } from '../models';

@Injectable({
  providedIn: 'root',
})
export class VerificationService {
  constructor(private appService: AppService) {}
  async verifyNin(data: { nin: string }): Promise<IVerifyNin> {
    return lastValueFrom(
      this.appService.post(`${APIConstant.verify.nin}`, data)
    );
  }
  async verifyPhone(data: { phoneNumber: string }): Promise<any> {
    return lastValueFrom(
      this.appService.post(`${APIConstant.verify.phone}`, data, {
        params: {
          phoneNumber: data.phoneNumber,
        },
      })
    );
  }
  async verifyCode(data: { phoneNumber: string; code: string }): Promise<any> {
    return lastValueFrom(
      this.appService.post(`${APIConstant.verify.verifyCode}`, data, {
        params: {
          phoneNumber: data.phoneNumber,
          code: data.code,
        },
      })
    );
  }
  async sendVerificationEmail(data: { email: string }) {
    return lastValueFrom(
      this.appService.post(
        `${APIConstant.auth.sendEmailVerificationCode}`,
        data
      )
    );
  }

  async verifyEntity(params: { criteria: string }): Promise<any> {
    return lastValueFrom(
      this.appService.get(`${APIConstant.verify.verifyEntity}`, {
        criteria: params.criteria,
      })
    );
  }
}
